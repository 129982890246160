<template>
  <div class="container_margin">
    <h3 class="text-blue">
      <b>{{ "Choose Your Contracting Party" }}</b>
    </h3>
    <p class="text-blue">
      {{ "Your content depends on the role of your organization in the contract" }}
    </p>

    <div class="row">
      <div class="col-12 col-md-6 mt-3">
        <div class="h-100 card back-blue shadow p-4 d-flex flex-column align-items-center">
          <h5><b>{{ "Discloser" }}</b></h5>
          <span class="material-icons-outlined icon-blue">person</span>
          <p class="text-center my-3">
            The party that is disclosing<br>the confidential information to the other party
          </p>
          <button class="btn btn-white" @click="chooseCategory('Discloser')">
            {{ "Review as Discloser" }}
          </button>
        </div>
      </div>

      <div class="col mt-3">
        <div class="h-100 card shadow p-4 d-flex flex-column align-items-center text-blue">
          <h5><b>{{ "Recipient" }}</b></h5>
          <span class="material-icons-outlined icon-white">person</span>
          <p class="text-center my-3">
            The party that is receiving<br>the confidential information from the other party
          </p>
          <button class="btn btn-primary" @click="chooseCategory('Recipient')">
            {{ "Review as Recipient" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  methods: {
    chooseCategory(data) {
      this.$router.push({
        name: `${data}Page`,
        params: {
          id: data,
        },
      });
    },
  },
};
</script>
<style scoped>
.container_margin {
  padding: 2rem;
}

.back-blue {
  background: #094890;
  color: white;
}

.icon-blue {
  font-size: 4rem;
  color: #fff;
}

.icon-white {
  font-size: 4rem;
  color: #094890;
}

.btn-white {
  color: #094890;
  background: white;
}
</style>